import React from 'react';
import { Button, Typography, Box, IconButton, Tooltip } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EditIcon from '@mui/icons-material/Edit';
import { Event } from '../types/Event';

interface Props {
  event: Event;
  onJoin: (event: Event) => void;
  onManage: (event: Event) => void;
  isAuthenticated: boolean;
}

const EventButton: React.FC<Props> = ({ event, onJoin, onManage, isAuthenticated }) => {
  const shareToWhatsApp = (e: React.MouseEvent) => {
    e.stopPropagation(); // 防止主按鈕點擊事件
    const message = encodeURIComponent(
      `查詢以下活動\n\n日期：${event.date}\n時間：${event.time}\n地點：${event.location}`
    );
    window.open(`https://wa.me/85255053400?text=${message}`, '_blank');
  };

  const handleManage = (e: React.MouseEvent) => {
    e.stopPropagation();
    onManage(event);
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  const getDayOfWeek = (dateString: string): string => {
    const daysOfWeek = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
    const date = new Date(dateString);
    return daysOfWeek[date.getDay()];
  };

  return (
    <Button
      variant="contained"
      fullWidth
      onClick={() => onJoin(event)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'left',
        py: 2,
        px: 3,
        position: 'relative',
      }}
    >
      <Typography variant="body2">日期：{formatDate(event.date)} ({getDayOfWeek(event.date)})</Typography>
      <Typography variant="body2">時間：{event.time}</Typography>
      <Typography variant="body2">地點：{event.location}</Typography>
      <Typography variant="body2">
        費用：${event.memberFee} / ${event.normalFee}
      </Typography>
      <Typography variant="body2">{event.otherDetails}</Typography>
      <Typography variant="caption" sx={{ mt: 1 }}>
        參與者：{event.participants.reduce((sum, p) => sum + p.spots, 0)}/{event.maxParticipants}
      </Typography>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: 16,
          transform: 'translateY(-50%)',
          display: 'flex',
          gap: 1,
        }}
      >
        {!isAuthenticated &&
          <Tooltip title="WhatsApp查詢">
            <IconButton
              onClick={shareToWhatsApp}
              sx={{
                backgroundColor: '#25D366', // WhatsApp 綠色
                color: 'white',
                '&:hover': {
                  backgroundColor: '#128C7E', // 懸停時的深色陰影
                },
              }}
            >
              <WhatsAppIcon />
            </IconButton>
          </Tooltip>
        }
        {isAuthenticated && (
          <Tooltip title="管理活動">
            <IconButton
              onClick={handleManage}
              sx={{
                backgroundColor: 'primary.main',
                color: 'inherit',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                },
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Button>
  );
};

export default EventButton;