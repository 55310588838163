import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline, Container } from '@mui/material';
import EventList from './components/EventList';
import { AuthProvider } from './contexts/AuthContext';
import Login from './components/Login';
// import Signup from './components/Signup';

const theme = createTheme({
  palette: {
    primary: {
      main: '#a9d0f7',
    },
  },
});

function App() {

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Container maxWidth="sm">
            <Routes>
              <Route path="/" element={<h1>Coming Soon</h1>} />
              <Route path="/login" element={<Login />} />
              <Route path="/:route" element={<EventList />} />
              {/* <Route path="/signup" element={<Signup />} /> */}
            </Routes>
          </Container>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;