import React, { useState, useEffect, useCallback } from 'react';
import { Stack, CircularProgress, Alert, Button, Box, Typography, Backdrop } from '@mui/material';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Event } from '../types/Event';
import EventButton from './EventButton';
import JoinModal from './JoinModal';
import ManageEventModal from './ManageEventModal';
import AddEventModal from './AddEventModal';
import { useAuth } from '../contexts/AuthContext';
import { API_BASE_URL } from '../config';

const EventList: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
  const [isJoinModalOpen, setIsJoinModalOpen] = useState(false);
  const [isManageModalOpen, setIsManageModalOpen] = useState(false);
  const [siteName, setSiteName] = useState<string>('');
  const [siteNameLoading, setSiteNameLoading] = useState(true);
  const [siteNameError, setSiteNameError] = useState<string | null>(null);
  
  const { isAuthenticated, isLoading, checkAuth } = useAuth();
  const location = useLocation();

  const fetchSiteName = useCallback(async () => {
    const route = location.pathname.split('/')[1]; // Get the first part of the path
    try {
      const response = await axios.get(`${API_BASE_URL}/public/site/${route}`);
      setSiteName(response.data.siteName);
      setSiteNameLoading(false);
    } catch (err) {
      console.error('Error loading site name:', err);
      if (axios.isAxiosError(err) && err.response?.status === 404) {
        setSiteNameError(`找不到網站 "${route}"。`);
      } else {
        setSiteNameError('無法載入網站名稱。');
      }
      setSiteNameLoading(false);
    }
  }, [location.pathname]);

  const fetchEvents = useCallback(async () => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      };
      if (isAuthenticated && localStorage.getItem('token') !== undefined) {
        const response = await axios.get(`${API_BASE_URL}/events`, { headers });
        setEvents(response.data);
      } else {
        const response = await axios.get(`${API_BASE_URL}/public/events`);
        setEvents(response.data);
      }
      setLoading(false);
    } catch (err) {
      console.error('載入活動時發生錯誤:', err);
      setError('無法載入活動。請稍後再試。');
      setLoading(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const initializeAuth = async () => {
      await checkAuth();
      fetchEvents();
      fetchSiteName();
    };

    initializeAuth();
  }, [checkAuth, fetchEvents, fetchSiteName]);

  useEffect(() => {
    if (siteName) {
      document.title = `${siteName} | MatchPoint`;
    }
  }, [siteName]);

  const handleEventUpdated = () => {
    fetchEvents();
  };

  const handleJoinClick = (event: Event) => {
    setSelectedEvent(event);
    setIsJoinModalOpen(true);
  };

  const handleManageClick = (event: Event) => {
    setSelectedEvent(event);
    setIsManageModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedEvent(null);
    setIsJoinModalOpen(false);
    setIsManageModalOpen(false);
  };

  const handleCloseAddEventModal = () => {
    setIsAddEventModalOpen(false);
  };

  const handleEventAdded = () => {
    fetchEvents();
  };

  const handleEventDeleted = () => {
    setIsManageModalOpen(false);
    fetchEvents();
  };

  if (isLoading || loading || siteNameLoading) {
    return (
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={true}
      >
        <CircularProgress color="inherit" />
        <Typography variant="h6" sx={{ mt: 2 }}>載入中...</Typography>
      </Backdrop>
    );
  }

  if (siteNameError) {
    return (
      <Box sx={{ textAlign: 'center', my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          網站未找到
        </Typography>
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <>
      <Box sx={{ textAlign: 'center', my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {siteName}
        </Typography>
      </Box>
      {isAuthenticated && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsAddEventModalOpen(true)}
          sx={{ mb: 2 }}
        >
          新增活動
        </Button>
      )}
      <Stack spacing={2}>
        {events.map((event) => (
          <EventButton 
            key={event._id}
            event={event} 
            onJoin={() => handleJoinClick(event)}
            onManage={() => handleManageClick(event)}
            isAuthenticated={isAuthenticated}
          />
        ))}
      </Stack>
      {selectedEvent && isJoinModalOpen && (
        <JoinModal
          event={selectedEvent}
          onClose={handleCloseModal}
          onEventUpdated={handleEventUpdated}
        />
      )}
      {selectedEvent && isManageModalOpen && (
        <ManageEventModal
          event={selectedEvent}
          onClose={handleCloseModal}
          onEventUpdated={handleEventUpdated}
          onEventDeleted={handleEventDeleted}
        />
      )}
      {isAddEventModalOpen && (
        <AddEventModal
          onClose={handleCloseAddEventModal}
          onEventAdded={handleEventAdded}
        />
      )}
    </>
  );
};

export default EventList;
